<template>
  <CHeader fixed with-subheader light class="TheHeader align-middle" style="position: relative" >
<!--    style="background-color: #0a3e0a"-->
    <CToggler
        in-header
        class="ml-3 d-lg-none"
        @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="$store.commit('toggleSidebarDesktop')"
    />
<!--    <div class="text-center mt-auto mb-auto user-info-small text-dark" >-->
<!--      <h5>{{$store.state.pageTitle}}</h5>-->
<!--    </div>-->

    <div class="absolute-center" >
<!--      <h4 class="text-light">オキシコネクト</h4>-->
      <img src="img/OxyConnect.png" height="50px" @click="logoClick"/>
    </div>

<!--    <CHeaderNav class="d-md-down-none mr-auto">-->
<!--      <span v-if="$store.state.user.role == 'SUPER'" v-shortkey="['ctrl', 'l']" @shortkey="logout">アドミニストレータ</span>-->
<!--      <span v-else-if="$store.state.user.role == 'REGISTER'" v-shortkey="['ctrl', 'l']" @shortkey="logout">レジストレータ</span>-->
<!--      <span v-else-if="$store.state.user.role == 'AGENCY'" v-shortkey="['ctrl', 'l']" @shortkey="logout">プロバイダ</span>-->
<!--      <span v-else-if="$store.state.user.role == 'DOCTOR'" v-shortkey="['ctrl', 'l']" @shortkey="logout">医療関係者</span>-->

<!--      &lt;!&ndash;      <CHeaderNavItem class="px-3">&ndash;&gt;-->
<!--      &lt;!&ndash;        <CHeaderNavLink to="/dashboard">&ndash;&gt;-->
<!--      &lt;!&ndash;          Dashboard&ndash;&gt;-->
<!--      &lt;!&ndash;        </CHeaderNavLink>&ndash;&gt;-->
<!--      &lt;!&ndash;      </CHeaderNavItem>&ndash;&gt;-->
<!--      &lt;!&ndash;      <CHeaderNavItem class="px-3">&ndash;&gt;-->
<!--      &lt;!&ndash;        <CHeaderNavLink to="/users" exact>&ndash;&gt;-->
<!--      &lt;!&ndash;          Users&ndash;&gt;-->
<!--      &lt;!&ndash;        </CHeaderNavLink>&ndash;&gt;-->
<!--      &lt;!&ndash;      </CHeaderNavItem>&ndash;&gt;-->
<!--      &lt;!&ndash;      <CHeaderNavItem class="px-3">&ndash;&gt;-->
<!--      &lt;!&ndash;        <CHeaderNavLink>&ndash;&gt;-->
<!--      &lt;!&ndash;          Settings&ndash;&gt;-->
<!--      &lt;!&ndash;        </CHeaderNavLink>&ndash;&gt;-->
<!--      &lt;!&ndash;      </CHeaderNavItem>&ndash;&gt;-->

<!--    </CHeaderNav>-->


    <CHeaderNav class="" style="position: fixed;right: 0;" >
      <div class="text-center mt-auto mb-auto user-info-small text-dark" >
        <div>{{ userRoleName }}</div>
        <!--      <div v-else-if="$store.state.user.role == 'REGISTRATOR'" v-shortkey="['ctrl', 'l']" @shortkey="logout">レジストレータ</div>-->
        <!--      <div v-else-if="$store.state.user.role == 'MAINTENANCE'" v-shortkey="['ctrl', 'l']" @shortkey="logout">メンテナンス</div>-->
        <!--      <div v-else-if="$store.state.user.role == 'PROVIDER'" v-shortkey="['ctrl', 'l']" @shortkey="logout">プロバイダ</div>-->
        <!--      <div v-else-if="$store.state.user.role == 'DOCTOR'" v-shortkey="['ctrl', 'l']" @shortkey="logout">医療関係者</div>-->
        <div style="font-size: 12px;">[{{$store.state.user.userName}}]</div>
      </div>
<!--      <CHeaderNavItem class="d-md-down-none mx-2">-->
<!--        <CHeaderNavLink>-->
<!--          <CIcon name="cil-bell"/>-->
<!--        </CHeaderNavLink>-->
<!--      </CHeaderNavItem>-->
<!--      <CHeaderNavItem class="d-md-down-none mx-2">-->
<!--        <CHeaderNavLink>-->
<!--          <CIcon name="cil-list"/>-->
<!--        </CHeaderNavLink>-->
<!--      </CHeaderNavItem>-->
<!--      <CHeaderNavItem class="d-md-down-none mx-2">-->
<!--        <CHeaderNavLink>-->
<!--          <CIcon name="cil-envelope-open"/>-->
<!--        </CHeaderNavLink>-->
<!--      </CHeaderNavItem>-->
      <TheHeaderDropdownAccnt/>

      <CButton color="warning" class="ml-1 mr-1" size="sm" @click="logout">ログアウト</CButton>
    </CHeaderNav>

    <!-- <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader> -->
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

import CommonMix from '../views/aprod/js/mixin/CommonUtils'
export default {
        name: 'TheHeader',
        mixins:[CommonMix],
        data(){
          return {
            userRoleName:null
          }
        },
        mounted() {
          this.userRoleName = this.getRoleName(this.$store.state.user.role)
        },
  components: {
            TheHeaderDropdownAccnt
        }, methods: {
            logout: function () {
                this.axios.post('/api/user/logout', {}, {headers: {'Content-Type': 'application/json'}}).then(response => {
                    // this.$router.replace("/");
                  window.location = "/";
                }).catch(error => console.log(error)
                ).finally(() => this.isLoading = false);
            },
            logoClick(){
              //location.href="/#/pages/notice/list";
            }
        },computed:{
          pageTitle(){
           return this.$store.state.pageTitle;
          }
  }
    }
</script>
<style>

  .user-info-small  {
    font-size: 10px;
  }
</style>