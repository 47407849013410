var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CHeader",
    {
      staticClass: "TheHeader align-middle",
      staticStyle: { position: "relative" },
      attrs: { fixed: "", "with-subheader": "", light: "" }
    },
    [
      _c("CToggler", {
        staticClass: "ml-3 d-lg-none",
        attrs: { "in-header": "" },
        on: {
          click: function($event) {
            return _vm.$store.commit("toggleSidebarMobile")
          }
        }
      }),
      _c("CToggler", {
        staticClass: "ml-3 d-md-down-none",
        attrs: { "in-header": "" },
        on: {
          click: function($event) {
            return _vm.$store.commit("toggleSidebarDesktop")
          }
        }
      }),
      _c("div", { staticClass: "absolute-center" }, [
        _c("img", {
          attrs: { src: "img/OxyConnect.png", height: "50px" },
          on: { click: _vm.logoClick }
        })
      ]),
      _c(
        "CHeaderNav",
        { staticStyle: { position: "fixed", right: "0" } },
        [
          _c(
            "div",
            {
              staticClass:
                "text-center mt-auto mb-auto user-info-small text-dark"
            },
            [
              _c("div", [_vm._v(_vm._s(_vm.userRoleName))]),
              _c("div", { staticStyle: { "font-size": "12px" } }, [
                _vm._v("[" + _vm._s(_vm.$store.state.user.userName) + "]")
              ])
            ]
          ),
          _c("TheHeaderDropdownAccnt"),
          _c(
            "CButton",
            {
              staticClass: "ml-1 mr-1",
              attrs: { color: "warning", size: "sm" },
              on: { click: _vm.logout }
            },
            [_vm._v("ログアウト")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }