var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CFooter", { attrs: { fixed: false } }, [
    _c("div", [
      _c("a", { attrs: { href: "https://coreui.io", target: "_blank" } }, [
        _vm._v("CoreUI")
      ]),
      _c("span", { staticClass: "ml-1" }, [
        _vm._v("© " + _vm._s(new Date().getFullYear()) + " creativeLabs.")
      ])
    ]),
    _c("div", { staticClass: "ml-auto" }, [
      _c("span", { staticClass: "mr-1" }, [_vm._v("Powered by")]),
      _c("a", { attrs: { href: "https://coreui.io/vue", target: "_blank" } }, [
        _vm._v("CoreUI for Vue")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }