var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-app" },
    [
      _c("TheSidebar"),
      _c(
        "CWrapper",
        [
          _vm.$store.state.showHeader ? _c("TheHeader") : _vm._e(),
          _c("div", { staticClass: "c-body" }, [
            _c(
              "main",
              {
                staticClass: "c-main",
                staticStyle: { "background-color": "white" }
              },
              [
                _c(
                  "CContainer",
                  { staticClass: "c-contents", attrs: { fluid: "" } },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade" } },
                      [_c("router-view")],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }